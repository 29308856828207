import Vue from 'vue'
import store from './store/store'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import defaultAxios from "./defaultAxios";
import global from "./mixins/global";
import VueRouter from 'vue-router'
import routes from './routes/routes'

//import lodash
import lodash from 'lodash'
Vue.prototype._ = lodash



window.axios = defaultAxios
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.config.productionTip = false
Vue.use(store)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueRouter)
Vue.mixin(global)
import Default from './layout/defaultLayout.vue';
Vue.component('default-layout', Default);
const router = new VueRouter({
    routes,
    linkActiveClass: 'active-nav',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

import VueCookies from 'vue-cookies'

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
Vue.use(VueCookies, { expires: '2h'})


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret, faTrash, faEdit, faCheck, faTimes 
,faSave} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
    faUserSecret,
    faTrash,
    faEdit,
    faCheck,
    faTimes,
    faSave
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

Vue.component('multiselect', Multiselect);

window.moment = require('moment');

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')