// mixin 
export default {
    data() {
        return {
            accounts: [],
            valid_currencies: [
                "hkd",
                "usd",
                "gbp",
                "jpy",
                "cad",
                "aud",
                "sgd",
                "twd",
                "chf",
                "cny",
                "krw",
                "thb",
                "myr",
                "dem",
                "nlg",
                "bef",
                "frf",
                "itl",
                "eur",
                "php",
                "inr",
                "idr",
                "zar",
            ]
        }
    },
    mounted() {
        this.checkExpiry()
    },
    computed: {
        currenciesOptions() {
            let options = []
            for (let i = 0; i < this.valid_currencies.length; i++) {
                options.push({
                    value: this.valid_currencies[i],
                    text: this.valid_currencies[i].toUpperCase()
                })
            }
            return options
        }
    },
    methods: {
        checkExpiry() {
            const expiry = this.$store.state.token_expiry
            if (!expiry) return;
            const timeToExpire = (expiry - new Date()) / 1000;
            //turn timeToExpire to minutes
            const minutesToExpire = Math.floor(timeToExpire / 60);
            //turn timeToExpire to seconds
            const secondsToExpire = Math.floor(timeToExpire % 60);
            // see if token expiry is imminent
            if (parseInt(minutesToExpire) < 15 && parseInt(minutesToExpire) > 0) {
                this.$store.dispatch('refreshToken')
            }
        },
        $fetchAccounts(loading) {
            loading = true;
            axios.get('/api/accounts')
                .then(response => {
                    const accounts = response.data.accounts
                    for (let i = 0; i < accounts.length; i++) {
                        const type = accounts[i].name
                        for (let j = 0; j < accounts[i].accounts.length; j++) {
                            this.accounts.push({
                                value: accounts[i].accounts[j]._id,
                                text: `${type} - ${accounts[i].accounts[j].name}`
                            })
                        }
                    }
                    loading=false;
                })
                .catch(error => {
                    this.$swal.fire({
                        title: 'Error Getting Accounts',
                        text: error.response.data.message,
                        icon: 'error'
                    })
                    console.error(error)
                })

        }

    }
}