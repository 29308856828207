<template>
  <header>
    <nav>

      <a href="/#/"><img src="/img/Logo-03.svg" height="40px"/></a>
      <ul class="left-links">
        <li><a href="/#/Invoicing">Invoicing</a></li>
        <li><a href="/#/Purchasing">Purchasing</a></li>
        <li><a href="/#/BankAccounts">Bank Accounts</a></li>
        <li><a href="/#/Transactions">Transactions</a></li>
        <li><a href="/#/ChartOfAccounts">Chart of Accounts</a></li>
      </ul>
      <ul class="right-links">
        <li><a href="/" @click.prevent="logout">Logout</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'CommonHeader',
  methods: {
    logout() {
      this.$store.dispatch('logout')
    }
  }
};
</script>

<style scoped>
header {
  background-color: #32ffd3;
  color: white;
  padding: 10px;
  margin:auto
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding:0 1em;
  color: white;
  max-width: 1280px;
  margin:auto
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav .left-links {
  display: flex;
  flex-direction: row;
}

nav .left-links li {
  margin-right: 50px;
}

nav .right-links {
  display: flex;
  flex-direction: row-reverse;
}

nav .right-links li {
  margin-left: 10px;
}
</style>
